import * as React from "react";
import { v4 as uuidv4 } from "uuid";

type Form = {
  user_session: string,
  showBanner: boolean,
  bannerContent: {
    bannerDetails?: string,
    bannerTitle?: string,
    endTime?: string,
    level?: string,
    startTime?: string
  },
  nonAuth: boolean,
  nonPending: boolean,
  render: boolean,
  country: string,
  apiError: boolean,
  fawError: boolean,
  orderNumber: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  userName: string,
  subscriptionIds: string[],
  dataCenterRegion: string,
  dataCenterRegionId: string,
  serviceGroupNames: string[],
  homeRegions: {},
  password: string,
  confirmPassword: string,
  tenancyName: string,
  homeRegion: any,
  regionGroup: any,
  processing: boolean,
  submitting: boolean,
  loading: boolean,
  loadingFeatureFlags: boolean,
  showThankYou: boolean,
  consoleUri: any,
  singleOrderState: any,
  tokenLowerCase: boolean,
  tokenHasExpired: boolean,
  customerType: any,
  isInternalCustomerType: boolean,
  domainType: any,
  tenancyTypes: {},
  regionName: string,
  companyName: string,
  bannerSrc: string,
  call2tenls: boolean,
  featureFlags: any,
};

const blankForm: Form = {
  nonAuth: false,
  nonPending: false,
  user_session: "activation_" + uuidv4(),
  apiError: false,
  fawError: false,
  render: true,
  country: "",
  firstName: "",
  lastName: "",
  subscriptionIds: [],
  userName: "",
  emailAddress: "",
  password: "",
  confirmPassword: "",
  tenancyName: "",
  homeRegion: null,
  regionGroup: null,
  dataCenterRegion: "",
  dataCenterRegionId: "",
  serviceGroupNames: [],
  homeRegions: {},
  showBanner: false,
  bannerContent: {},
  processing: false,
  submitting: false,
  loading: true,
  loadingFeatureFlags: true,
  showThankYou: false,
  consoleUri: "",
  orderNumber: "",
  singleOrderState: null,
  tokenHasExpired: false,
  tokenLowerCase: false,
  isInternalCustomerType: false,
  customerType: null,
  domainType: {label:'', value:'DefaultDomain'},
  tenancyTypes: {},
  regionName: "",
  companyName: "Oracle Cloud",
  bannerSrc: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='128' height='100' viewBox='0 0 32 25'%3E%3Cg fill='%23C74634'%3E%3Cpath d='M9.9,20.1c-5.5,0-9.9-4.4-9.9-9.9c0-5.5,4.4-9.9,9.9-9.9h11.6c5.5,0,9.9,4.4,9.9,9.9c0,5.5-4.4,9.9-9.9,9.9H9.9 M21.2,16.6c3.6,0,6.4-2.9,6.4-6.4c0-3.6-2.9-6.4-6.4-6.4h-11c-3.6,0-6.4,2.9-6.4,6.4s2.9,6.4,6.4,6.4H21.2'/%3E%3C/g%3E%3C/svg%3E",
  call2tenls: false,
  featureFlags: [],
};

type FormProviderProps = {children: React.ReactNode}
type FormAction = {type: string, payload: string, object?: any, boolean?: boolean}
type FormDispatch = (action: FormAction) => void

const FormStateContext = React.createContext<Form | undefined>(undefined)
const FormDispatchContext = React.createContext<FormDispatch | undefined>(undefined,)

function formReducer(form: Form, action: FormAction) {
  switch (action.type) {
    case 'UPDATE_NONAUTH':
      return {
        ...form,
        nonAuth: action.boolean? action.boolean : false
      }
    case 'UPDATE_NONPENDING':
      return {
        ...form,
        nonPending: action.boolean? action.boolean : false,
        singleOrderState: action.payload
      }
    case 'PLACE_DEFAULTS':
      return {
        ...form,
        firstName: action.object.firstName,
        lastName: action.object.lastName,
        userName: action.object.emailAddress,
        emailAddress: action.object.emailAddress,
        orderNumber: action.object.orderNumber,
        subscriptionIds: action.object.subscriptionIds,
        dataCenterRegion: action.object.dataCenterRegion,
        dataCenterRegionId: action.object.dataCenterRegionId,
        serviceGroupNames: action.object.serviceGroupNames,
        customerType: action.object.customerType,
        regionName: action.object.customerType,
      }
    case 'UPDATE_FIRST_NAME':
      return {
        ...form,
        firstName: action.payload
      }
    case 'UPDATE_LAST_NAME':
      return {
        ...form,
        lastName: action.payload
      }
    case 'UPDATE_EMAIL_ADDRESS':
      return {
        ...form,
        emailAddress: action.payload
      }
    case 'UPDATE_PASSWORD':
      return {
        ...form,
        password: action.payload
      }
    case 'UPDATE_CONFIRM_PASSWORD':
      return {
        ...form,
        confirmPassword: action.payload
      }
    case 'UPDATE_TENANCY_NAME':
      return {
        ...form,
        tenancyName: action.payload
      }
    case 'HOME_REGIONS_LIST':
      return {
        ...form,
        homeRegions: action.object
      }
    case 'UPDATE_HOME_REGION':
      return {
        ...form,
        homeRegion: action.object
      }
    case 'UPDATE_REGION_GROUP':
      return {
        ...form,
        regionGroup: action.object
      }
    case 'UPDATE_APIERROR':
      return {
        ...form,
        apiError: action.boolean? action.boolean : false
      }
    case 'UPDATE_FAW_ERROR':
      return {
        ...form,
        fawError: action.boolean? action.boolean : false
      }
    case 'UPDATE_SHOW_BANNER':
      return {
        ...form,
        showBanner: action.boolean? action.boolean : false
      }
    case 'UPDATE_BANNER_CONTENT':
      return {
        ...form,
        bannerContent: action.object
      }
    case 'SUBMIT_CREATE_TENANCY_INFO':
      return {
        ...form,
        processing: action.boolean? action.boolean : false,
        submitting: action.boolean? action.boolean : false
      }
    case 'GETTING_AUTH':
      return {
        ...form,
        loading: action.boolean? action.boolean : false
      }
    case 'GETTING_FEATURE_FLAGS':
      return {
        ...form,
        loadingFeatureFlags: action.boolean? action.boolean : false
      }
    case 'SHOW_THANK_YOU':
      return {
        ...form,
        processing: false,
        showThankYou: action.boolean? action.boolean : false
      }
    case 'ORDER_HAS_EXPIRED':
      return {
        ...form,
        tokenHasExpired: action.boolean? action.boolean : false
      }
    case 'TOKEN_IS_LOWERCASE':
      return {
        ...form,
        tokenLowerCase: action.boolean? action.boolean : false
      }
    case 'UPDATE_INTERNAL_CUSTOMER_TYPE':
      return {
        ...form,
        isInternalCustomerType: action.boolean? action.boolean : false
      }
    case 'UPDATE_CUSTOMER_TYPE':
      return {
        ...form,
        customerType: action.payload
      }
    case 'UPDATE_DOMAIN_TYPE':
      return {
        ...form,
        domainType: action.object
      }
    case 'UPDATE_TENANCY_LIST':
      return {
          ...form,
          tenancyTypes: action.object
      }
    case 'UPDATE_REGION_NAME':
      return {
        ...form,
        regionName: action.payload
      }
    case 'UPDATE_COMPANY_NAME':
      return {
        ...form,
        companyName: action.payload
      }
    case 'UPDATE_LOGO_IMG_SRC':
      return {
        ...form,
        bannerSrc: action.payload
      }
    case 'UPDATE_CALL_2_TENLS':
      return {
        ...form,
        call2tenls: action.boolean? action.boolean : false
      }
    case 'SET_FEATURE_FLAGS':
      return {
        ...form,
        featureFlags: action.object
      }
    default: {
      throw new Error(`Unhandled action type ${action.type}`)
    }
  }
}

function FormProvider({children}: FormProviderProps) {
  const [state, dispatch] = React.useReducer(formReducer, blankForm)
  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>
        {children}
      </FormDispatchContext.Provider>
    </FormStateContext.Provider>
  )
}

function useFormState() {
  const context = React.useContext(FormStateContext)
  if (context === undefined) {
    throw new Error('useFormState must be used within a FormProvider')
  }
  return context
}

function useFormDispatch() {
  const context = React.useContext(FormDispatchContext)
  if (context === undefined) {
    throw new Error('useFormDispatch must be used within a FormProvider')
  }
  return context
}

export {FormProvider, useFormState, useFormDispatch}
