import {base_url, tenls_url, ux_plugin_url} from '../config/apiConfig';
import * as _ from 'lodash';


export const fetchFeatureFlags = () => {
    return fetch(`${base_url}/featureFlags`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
};

export const fetchTenlsFeatureFlags = (token) => {
    if (tenls_url === '') {
        return Promise.resolve({ status: 404, json: () => Promise.resolve() })
    }
    return fetch(`${tenls_url}/featureFlag?token=${token}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
};

export const fetchOrders = (token, call2Tenls) => {
    const fetchURL = `${call2Tenls ? tenls_url : base_url}/orders?token=${token}`;
    return fetch(
        fetchURL,
        {
          method: 'GET',
        headers: {'Content-Type': 'application/json'}
        }
    );
};

export const fetchAccountNameValidation = (nameAttempt) => {
    //Get JWT toke from url query
    const token = new URLSearchParams(window.location.search).get('activationToken');
    const decoded = jwt2Obj(token);
    const lHeaders = _.get(decoded, 'version', '') === 'V2'
        ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
        : {'Content-Type': 'application/json'};
  return fetch(
    `${base_url}/validateAccountName`,
    {
      method: 'POST',
      headers: lHeaders,
      body: JSON.stringify({ name: nameAttempt })
    }
  );
};

export const fetchBanner = () => {
  return fetch(
    `${base_url}/banner`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    }
  );
};

export const fetchRegions = (path) => {
    const clientIdParam = path.includes('?') && path.includes('=') ? `&clientId=ActivateUI` : '?clientId=ActivateUI';
    return fetch(
        `${base_url}${path}${clientIdParam}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
    });
};

export const fetchCreateTenancy = (token, body) => {
    const decoded = jwt2Obj(token);
    const lHeaders = _.get(decoded, 'version', '') === 'V2'
                ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
                : {'Content-Type': 'application/json'};

  return fetch(
    `${base_url}/tenancies?token=${token}`,
    {
      method: 'POST',
      headers: lHeaders,
      body: body
    }
  );
};

export const fetchAsset = (path) => {
    return fetch(
        `${ux_plugin_url}${path}`,
        { method: 'GET' }
    );
};

const jwt2Obj = (token) => {
    let result;
    try {
        result = JSON.parse(Buffer.from(_.split(token, '.')[1], 'base64').toString());
    } catch {
        result = null;
    }
    return result;
};

export const getFeatureFlag = (featureName, featureFlags) => {
    if (!_.isEmpty(featureFlags)) {
        const index = _.findIndex(featureFlags, (o) => o.featureName === featureName);
        return index >= 0 ? featureFlags[index].isActive : false;
    }

    return false;
};

